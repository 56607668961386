import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import CropperImage from "../CropperImage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { isNull } from "lodash";
import {Modal, Button} from "react-bootstrap"
import {CreateMenuGroup} from '../create/CreateMenuGroup'
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditMenuBuffet = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: "112001" ,defaultMessage:"Buffet Menu" }));
  const history = useHistory();
  const classes = useStyles();
  const [group_id, setGroup_id] = useState();
  const [template_id, setTemplate_id] = useState();
  const [template, setTemplate] = useState([]);
  const [optionTemplate, setOptionTemplate] = useState([]);
  const [cloneTemplate, setCloneTemplate] = useState(false);
  const [item_name, setItem_name] = useState();
  const [item_name_en, setItem_name_en] = useState();
  const [item_name_cn, setItem_name_cn] = useState(null);
  const [description, setDescription] = useState();
  const [description_en, setDescription_en] = useState();
  const [description_cn, setDescription_cn] = useState(null);
  const [description_receipt, setDescription_receipt] = useState();
  const [item_image_path, setItem_image_path] = useState();
  const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");
  const [uom, setUom] = useState();
  const [price, setPrice] = useState();
  const [state, setState] = useState(true);
  const [buffet_package, setBuffet_package] = useState(true);
  const [changeOption, setChangeOption] = useState(false);
  const [group, setGroup] = useState([]);
  const [displayOrder, setDisplayOrder] = useState(0)
  const [show, setShow] = useState(false);
  const [printer_zone_data, setprinter_zone_data] = useState([]);
  const [zoneprinter_data, setzoneprinter_data] = useState([]);
  const [enable_cn, setEnable_cn] = useState(false);
  const [changeName, setChangeName] = useState(false);
  const [template_name, setTemplateName] = useState("");

  const [stateItem_image_path, setStateItem_image_path] = useState({
    previewItem_image_path: null,
    rawItem_image_path: null,
    imageURLItem_image_path: null,
  });

  const [enableTimeCounting, setEnableTimeCounting] = useState(false);
  const [timePeriod, setTimePeriod] = useState("");

  const handleChangeNameOption = () => {
    setChangeName(!changeName);
  };
  const allDataGroup = [];

  const AlertError = (message) => {
    Swal.fire("Menu Management!", message, "error");
  };

  useEffect(() => {
    getGroup();
    getZonePrint();
    getTemplateGroup();
    setGroup_id(temp_store(props).location.state.row.group_id);
    console.log('props', props)
    if (temp_store(props).location.state.row.template_id) {
      setTemplate_id(temp_store(props).location.state.row.template_id);
      setOptionTemplate(temp_store(props).location.state.row.options_template);
      let show_option =
        temp_store(props).location.state.row.template_id !== null;
      setChangeOption(show_option);
    }
    setItem_name(temp_store(props).location.state.row.item_name);
    setItem_name_en(temp_store(props).location.state.row.item_name_en);
    setDescription(temp_store(props).location.state.row.description);
    setDescription_en(temp_store(props).location.state.row.description_en);
    setDescription_receipt(temp_store(props).location.state.row.description_receipt);
    setDisplayOrder(temp_store(props).location.state.row.displayOrder);
    setItem_image_path(temp_store(props).location.state.row.item_image_path);
    setItem_name_cn(temp_store(props).location.state.row.item_name_cn);
    setDescription_cn(temp_store(props).location.state.row.description_cn);
    setTemplateName(temp_store(props).location.state.row.template_name);

    if (temp_store(props).location.state.row.uom) {
      setUom(temp_store(props).location.state.row.uom);
    }

    setPrice(temp_store(props).location.state.row.price);
    setState(temp_store(props).location.state.row.enabled);

    let time_period = temp_store(props).location.state.row.buffet_time;
    setTimePeriod((time_period == null || time_period == '' ? null : time_period ));
    // setTimePeriod(temp_store(props).location.state.row.buffet_time);
    setBuffet_package(temp_store(props).location.state.row.is_choose)

    checkEnableCN();
  }, []);
  const checkEnableCN =()=> {
    let temp = localStorage.getItem('is_enable_cn')
    if (temp == '1') {
      setEnable_cn(true)
    }
  }
  const getZoneprinter_data = (id) => {
    try {
      let print = zoneprinter_data.find(data => Number(data.id) === Number(id))
      return zoneprinter_data.find(data => Number(data.id) === Number(id))
    } catch (error) {
      return ""
    }
  }

  const getZonePrint = async () => {
    let apiUrls = `${baseURL}/management/printerzone?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setzoneprinter_data(res.data.data);
        let print = res.data.data.find(data => Number(data.id) === Number(temp_store(props).location.state.row.printer_zone_id))
        setprinter_zone_data(print)

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroup = async () => {
    let apiUrls = `${baseURL}/management/group?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setGroup(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangeGroup = (event) => {
    setGroup_id(event.target.value);
  };
  const handleChangeTemplate = (event) => {
    setTemplate_id(event.target.value);
    let template_select = template.find(arr => arr.id == event.target.value)
    // console.log('template_select', template_select);
    if(template_select) {
      setOptionTemplate(template_select.options_template)
    } else {
      setOptionTemplate([])
    }
    setCloneTemplate(false)
  };
  const getTemplateGroup = async () => {
    let apiUrls = `${baseURL}/management/templatelist`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setTemplate(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeItem_image_path = async (e) => {
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      var reader = new FileReader();
      e.target.value = '';
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          var height = this.height;
          var width = this.width;
          if (height < 512 || width < 512) {
            Swal.fire(
              "Upload !",
              "At least you can upload a 512*512 photo size. !! ",
              "error"
            );
            e.target.value = '';
            return false;
          } else {
            setStateItem_image_path({
              rawItem_image_path: file,
              imageURLItem_image_path: e.target.result,
            });
            e.target.value = '';
            return true;
          }
        };
      };
    }
  };
  const handleImageUploadItem_image_path = async (file) => {
    var formdata = new FormData();
    formdata.append("image", file, URL.createObjectURL(file));
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${baseURL}/upload/`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let res = JSON.parse(result);
        console.log(res);
        setStateItem_image_path({
          previewItem_image_path: null,
          rawItem_image_path: null,
          imageURLItem_image_path: null,
        });
        setItem_image_path(`${imageURL}/${res.filename}`);
        setNameImageItem_image_path(res.filename);
      })
      .catch((error) => console.log("error", error));
  };

  const removeImageItem_image_path = async () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
    setItem_image_path("");
  };

  const handleCancelUploadItem_image_path = () => {
    setStateItem_image_path({
      previewItem_image_path: null,
      rawItem_image_path: null,
      imageURLItem_image_path: null,
    });
  };

  const handleChangeSwitch = () => {
    setState(!state);
  };

  const handleChangeSwitchPackage = () => {
    setBuffet_package(!buffet_package);
  };

  const handleChangeOption = () => {
    setChangeOption(!changeOption);
    if (changeOption) {
      setTemplate_id("");
      setOptionTemplate([]);
    }
  };

  const handleChangezoneprinter = (event, values) => {
    if (values) {
      setprinter_zone_data(values);
      console.log(values)
    } else {
      setprinter_zone_data();
    }
  };

  const onSubmit = async (data) => {
    if (item_name === undefined || item_name === "") {
      return AlertError("please insert item name.");
    }
    // if (item_name_en === undefined || item_name_en === "") {
    //   return AlertError("please insert item name en.");
    // }

    if (group_id === undefined || group_id === "") {
      return AlertError("please select group.");
    }

    if (price === undefined || price === "") {
      if (changeOption) {
        setPrice(0)
      } else return AlertError("please insert price.");
    }

    if (description_receipt === undefined || description_receipt === "") {
      return AlertError("please insert description receipt.");
    }

    if (displayOrder === undefined || displayOrder === '' || isNaN(displayOrder) || Number(displayOrder) === 0 || Number(displayOrder) < 0) {
      return AlertError('please insert display order.')
    }

    if (printer_zone_data === undefined || printer_zone_data === "" || printer_zone_data === null){
      return AlertError("please select printer zone")
    }

    let get_shop_id = localStorage.getItem("shop_id");
    let get_branch_id = localStorage.getItem("branch_id");

    var events = {
      shop_id: get_shop_id,
      branch_id: get_branch_id,
      group_id,
      template_id: template_id ? template_id : null,
      item_name,
      item_name_en,
      description: description ? description : " ",
      description_en: description_en ? description_en : " ",
      description_receipt: description_receipt ? description_receipt : " ",
      item_image_path: item_image_path ? item_image_path : " ",
      uom: uom,
      price: Number(price),
      printer_zone_id: printer_zone_data ? printer_zone_data.id : null,
      enabled: state,
      display_order: displayOrder === 0 ? 0 : displayOrder,
      option_template: optionTemplate,
      buffet_time: (timePeriod == null || timePeriod == '' ? null : timePeriod ),
      is_choose: buffet_package
    };

    let apiUrls = `${baseURL}/management/menu/${temp_store(props).location.state.row.id}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not update menu. !! " + err,
          "error"
        );
      });
  };
  
  console.log('optionTemplate ', optionTemplate, 'template_id', template_id)
  const BindOption = (type) => {
    return (
      <>
        {optionTemplate.map((group, index) => {
          let indexGroup = index;
          if (group.type == type) {
            return (
              <div className="option-box" key={index}>
                <div>
                  <div className="d-flex align-items-center">
                    <Switch
                      checked={group.is_active}
                      onChange={(e) => setOptionGroup(index, e, "is_active")}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      className="input-cheked-show"
                    />
                    <span className="ml-3">
                      {intl.formatMessage({
                        id: "###",
                        defaultMessage: "เปิด/ปิด option Group นี้",
                      })}
                    </span>
                  </div>
                  <div
                    className={` group-name d-flex align-items-center${
                      group.validate ? " validate" : ""
                    }`}
                  >
                    <div>
                      <span className="mr-5 name">
                        {intl.formatMessage({
                          id: "113121",
                          defaultMessage: "group name TH",
                        })}
                      </span>
                      <input
                        type="text"
                        minLength="100"
                        value={group.option_group_name}
                        onChange={(event) =>
                          setOptionGroup(index, event, "option_group_name")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`group-name d-flex align-items-center${
                      group.validate_en ? " validate" : ""
                    }`}
                  >
                    <div>
                      <span className="mr-5 name">
                        {intl.formatMessage({
                          id: "113122",
                          defaultMessage: "group name EN",
                        })}
                      </span>
                      <input
                        type="text"
                        minLength="100"
                        value={group.option_group_name_en}
                        onChange={(event) =>
                          setOptionGroup(index, event, "option_group_name_en")
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={`group-name d-flex align-items-center${
                      group.validate_en ? " validate" : ""
                    }`}
                  >
                    {enable_cn && <div>
                      <span className="mr-5 name">{intl.formatMessage({ id: "103124", defaultMessage: "group name CN" })}</span>
                      <input type="text" minLength="100" value={group.option_group_name_cn} onChange={(event) => setOptionGroup(index, event, "option_group_name_cn")} />
                    </div>}

                    <span
                      className="option-delete d-flex text-primary ml-3 btn-custom"
                      onClick={() => deleteGroup(index)}
                    >
                      <i className="fa fa-trash mr-3 text-primary"></i>
                      {intl.formatMessage({
                        id: "104038",
                        defaultMessage: "delete",
                      })}
                    </span>
                  </div>
                </div>
                <div className="option-list">
                  <div className="d-flex align-items-center mb-1 font-weight-bold">
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "###",
                        defaultMessage: "status",
                      })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "104039",
                        defaultMessage: "Option name th",
                      })}
                    </span>
                    <span className="option-name col-4 mr-1">
                      {intl.formatMessage({
                        id: "103123",
                        defaultMessage: "Option name en",
                      })}
                    </span>
                    {enable_cn && <span className="option-name col-5 mr-1">{intl.formatMessage({ id: "103125", defaultMessage: "Option name cn" })}</span>}
                    <span className="option-price col-3 mr-5">
                      {intl.formatMessage({
                        id: "104040",
                        defaultMessage: "price",
                      })}
                    </span>
                    <span className="option-default">
                      {intl.formatMessage({
                        id: "104041",
                        defaultMessage: "show price",
                      })}
                    </span>
                  </div>
                  {group.options.map((option, index) => {
                    return (
                      <div
                        className="d-flex align-items-center mb-1"
                        key={index}
                      >
                        <div className="option-name col-4 mr-1" maxLength="40">
                          <Switch
                            checked={option.is_active}
                            onChange={(e) => setOption(indexGroup, index, e, "is_active")}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            className="input-cheked-show"
                          />
                        </div>
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="40"
                          type="text"
                          value={option.option_name}
                          onChange={(event) =>
                            setOption(indexGroup, index, event, "option_name")
                          }
                        />
                        <input
                          className="option-name col-4 mr-1"
                          maxLength="40"
                          type="text"
                          value={option.option_name_en}
                          onChange={(event) =>
                            setOption(
                              indexGroup,
                              index,
                              event,
                              "option_name_en"
                            )
                          }
                        />
                        {enable_cn &&<input className="option-name col-5 mr-1" maxLength="50" type="text" value={option.option_name_cn} onChange={(event) => setOption(indexGroup, index, event, 'option_name_cn')} />}
                        <input
                          className="option-price col-3 mr-5"
                          min="0"
                          type="number"
                          value={option.option_price}
                          onChange={(event) =>
                            setOption(indexGroup, index, event, "option_price")
                          }
                        />
                        <input
                          className="option-default btn-custom"
                          type="checkbox"
                          name={group.option_group_name}
                          checked={option.is_show_price}
                          onClick={(event) =>
                            setOption(indexGroup, index, event, "is_show_price")
                          }
                        />
                        <span
                          className="option-delete d-flex ml-3 text-primary btn-custom"
                          onClick={() => deleteOption(indexGroup, index)}
                        >
                          <i className="fa fa-trash mr-3 text-primary"></i>
                          {intl.formatMessage({
                            id: "104038",
                            defaultMessage: "delete",
                          })}
                        </span>
                      </div>
                    );
                  })}
                  <span className="span-border mb-1 mt-3"></span>
                  <span
                    className="option-delete d-flex text-primary px-5 py-3 btn-custom"
                    onClick={() => addOption(index)}
                  >
                    <i className="fa fa-plus-circle mr-3 text-primary"></i>
                    {intl.formatMessage({
                      id: "104042",
                      defaultMessage: "add option",
                    })}
                  </span>
                  {type === "multi" ? (
                    <div className="">
                      <div className="d-flex align-items-center mb-1 font-weight-bold">
                        <span className="option-name col-3 mr-1">
                          {intl.formatMessage({
                            id: "104044",
                            defaultMessage: "Minimum",
                          })}
                        </span>
                        <span className="option-price col-3">
                          {intl.formatMessage({
                            id: "104045",
                            defaultMessage: "Maximum",
                          })}
                        </span>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          className="col-3 mr-1"
                          type="number"
                          min="0"
                          value={group.option_pick_min}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_min")
                          }
                        />
                        <input
                          className="col-3 "
                          type="number"
                          min="0"
                          value={group.option_pick_max}
                          onChange={(event) =>
                            setOptionGroup(index, event, "option_pick_max")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          }
        })}
        <span className="span-border mb-1 mt-3"></span>
        <span
          className="option-delete d-flex text-primary px-5 py-3 btn-custom"
          onClick={() => addGroup(type)}
        >
          <i className="fa fa-plus-circle mr-3 text-primary"></i>
          {intl.formatMessage({ id: "104017", defaultMessage: "Cancel" })}
        </span>
      </>
    );
  };

  const setOptionGroup = (index, event, group) => {
    if (group == "is_active") {
      var newState = [...optionTemplate];
      newState[index][group] = !newState[index][group];
      setOptionTemplate(newState);
    } else {
      var newState = [...optionTemplate];
      newState[index][group] = event.target.value;
      setOptionTemplate(newState);
    }
  };
  const setOption = (indexGroup, index, event, option) => {
    let data
    if (option != 'is_show_price') {
      data = event.target.value
    } else {
      data = event.target.checked
    }
    var newState = [...optionTemplate];
    newState[indexGroup].options[index][option] = data
    setOptionTemplate(newState)
  }

  const addOption = (index) => {
    var newState = [...optionTemplate];
    if(newState[index]["option_group_name"] !== "") {
      newState[index]["validate"] = false
      newState[index]["options"].push({ id: null, option_name: '', option_price: 0, is_show_price: true })
    } else {
      newState[index]["validate"] = true
    }
    setOptionTemplate(newState)
  }
  const addGroup = (type) => {
    var newState = []
    newState.push({
      id: null,
      option_group_name: '',
      options: [],
      option_pick_min:type === "multi" ? 0 : 1,
      option_pick_max:type === "multi" ? 0 : 1,
      type,
      validate: false
    })
    setOptionTemplate([...optionTemplate, ...newState])
  }

  const deleteGroup = (index) => {
    var newState = [...optionTemplate];
    newState.splice(index, 1)
    setOptionTemplate(newState)
  }
  const deleteOption = (indexGroup, index) => {
    var newState = [...optionTemplate];
    newState[indexGroup].options.splice(index, 1)
    setOptionTemplate(newState)
  }

  const clone = () => {
    let boo = !cloneTemplate
    // console.log('clone', boo);
    setCloneTemplate(boo)
    if (boo) {
      setTemplate_id('')
    }
    
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePass = (data) => {
    console.log('handlePass');
    if (data === 'save') {
      getGroup()
    }
    handleClose()
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "112047" ,defaultMessage:"Edit Menu Buffet" })}></CardHeader>
        <CardBody>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
            </span>
              {intl.formatMessage({ id: "112015" ,defaultMessage:"Name Group" })}:{" "}
            </p>
            <div 
            className="d-flex"
            style={{
              width: "530px",
            }}>
              <select
                as="select"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={group_id}
                onChange={handleChangeGroup}
              >
                <option value="">{intl.formatMessage({ id: "112022" ,defaultMessage:"Please Select" })}</option>
                {group &&
                  group.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.group_name}
                      </option>
                    );
                  })}
              </select>
              <Button variant="light" className="ml-3 text-primary" onClick={handleShow}>
                <i className="fa fa-plus-circle mr-3 text-primary" />
                {intl.formatMessage({ id: "112023" ,defaultMessage:"Add Group" })}
              </Button>

              <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Body>
                  <CreateMenuGroup type={'dialog'} handle={handlePass}/>
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleClose}>
                    Save
                  </Button>
                </Modal.Footer> */}
              </Modal>
            </div>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
            </span>
              {intl.formatMessage({ id: "112016" ,defaultMessage:"Name Menu Thai" })}:{" "}
            </p>
            <input
              name="item_name"
              value={item_name}
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(item_name) => setItem_name(item_name.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {/* <span style={{ color: "red" }}>
                *
            </span> */}
              {intl.formatMessage({ id: "112017" ,defaultMessage:"Name Menu Eng" })}:{" "}
            </p>
            <input
              name="item_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={item_name_en}
              onChange={(item_name_en) =>
                setItem_name_en(item_name_en.target.value)
              }
            />
          </div>
          {enable_cn &&<div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {/* <span style={{ color: "red" }}>
                *
            </span> */}
              {intl.formatMessage({ id: "113129" ,defaultMessage:"Name Menu CH" })}:{" "}
            </p>
            <input
              name="item_name_en"
              maxLength="100"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={item_name_cn}
              onChange={(item_name_cn) =>
                setItem_name_cn(item_name_cn.target.value)
              }
            />
          </div>}
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112018" ,defaultMessage:"Menu Details" })}:{" "}
            </p>
            <input
              name="description"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description}
              onChange={(description) =>
                setDescription(description.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112019" ,defaultMessage:"Menu Details Engish" })}:{" "}
            </p>
            <input
              name="description_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_en}
              onChange={(description_en) =>
                setDescription_en(description_en.target.value)
              }
            />
          </div>
          {enable_cn &&<div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113130" ,defaultMessage:"Chinese Menu Details" })}:{" "}
            </p>
            <input
              name="description_cn"
              maxLength="250"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_cn}
              onChange={(description_cn) =>
                setDescription_cn(description_cn.target.value)
              }
            />
          </div>}
          <div className="form-inline" style={{ marginBottom: "25px" }}>

            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
            </span>
              {intl.formatMessage({ id: "112020" ,defaultMessage:"Menu Name on The Receipt" })}:{" "}
            </p>
            <input
              name="description_receipt"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={description_receipt}
              onChange={(description_receipt) =>
                setDescription_receipt(description_receipt.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: '25px' }}>
            <p style={{ marginTop: '15px', float: 'left', width: '20%' }}>
              <span style={{ color: "red" }}>
                *
            </span>
              {intl.formatMessage({ id: "112021" ,defaultMessage:"Display Order" })}:{' '}
            </p>
            <input
              name="displayOrder"
              type="number"
              style={{
                height: '38px',
                width: '530px',
                backgroundColor: '#F4F6F9',
                borderRadius: '5px',
                borderWidth: '0'
              }}
              value={displayOrder}
              onChange={displayOrder =>
                setDisplayOrder(displayOrder.target.value)
              }
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112035" ,defaultMessage:"Image" })} : <br></br>
              (512*512)
            </p>
            <div 
            className="d-flex"
            style={{
              width: "530px",
            }}>
              <input
                name="item_image_path"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                disabled
                value={item_image_path}
              />

              <span className="btn btn-primary btn-file wbt pt-4"
              style={{
                whiteSpace: "nowrap",
                width: "fit-content"
              }}>
                {intl.formatMessage({ id: "112036" ,defaultMessage:"Upload/Edit" })}
                <input type="file" onChange={handleChangeItem_image_path} />
              </span>

              <button
                type="button"
                className="btn btn-danger wbt"
                onClick={removeImageItem_image_path}
              >
                {intl.formatMessage({ id: "112037" ,defaultMessage:"Delete" })}
              </button>
            </div>
          </div>

          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "50%",
            }}
          >
            {stateItem_image_path.previewItem_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={stateItem_image_path.previewItem_image_path}
              />
            ) : item_image_path ? (
              <img
                style={{
                  width: "300px",
                  height: "300px",
                }}
                src={item_image_path}
              />
            ) : null}
          </div>
          {stateItem_image_path.imageURLItem_image_path ? (
            <CropperImage
              image={stateItem_image_path.imageURLItem_image_path}
              handleCancel={handleCancelUploadItem_image_path}
              handleUpload={handleImageUploadItem_image_path}
              scale={1}
            />
          ) : (
            ""
          )}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "112038" ,defaultMessage:"Unit" })}:{" "}
            </p>
            <input
              name="uom"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={uom}
              onChange={(uom) => setUom(uom.target.value)}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "112039" ,defaultMessage:"Price" })}:{" "}
            </p>
            <input
              name="price"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={price}
              onChange={(price) => setPrice(price.target.value)}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              <span style={{ color: "red" }}>
                *
            </span>
              {intl.formatMessage({ id: "112040" ,defaultMessage:"Printer Zone" })} :{" "}
            </p>

            {zoneprinter_data.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinter_data}
                  getOptionLabel={(option) => intl.locale == 'th' ? option.zone_name:option.zone_name_en}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={printer_zone_data ? printer_zone_data : ""}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label={intl.formatMessage({ id: "112041" ,defaultMessage:"Zone Printer" })}
                    />
                  )}
                />
              </div>
            ) : null}

          </div>

          <div className="form-inline d-none" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              ตัวเลือก:{" "}
            </p>
            <div
              style={{
                width: "530px",
              }}
            >
              <div className="d-flex align-items-center">
                <Switch
                  checked={changeOption}
                  onChange={() => handleChangeOption()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  className="input-cheked-show" 
                />
                <span className="ml-3">มีตัวเลือกสำหรับเมนูอาหาร</span>
              </div>
              <div className={`box-cheked-show${changeOption ? ' d-block' : ''}`}>
                <div className="template mb-3 d-flex align-items-center"
                style={{
                  width: "530px",
                }}>
                  <span className="mr-3">
                    รูปแบบ
                  </span>
                  <select
                    as="select"
                    style={{
                      flexGrow: "1",
                      height: "38px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={template_id}
                    onChange={handleChangeTemplate}
                  >
                    <option value="">กรุณาเลือก</option>
                    {template &&
                      template.map((result, index) => {
                        return (
                          <option key={index} value={result.id}>
                            {result.template_name}
                          </option>
                        );
                      })}
                  </select>
                  <div alt="clone template" className={`button-clone d-none${(template_id !== '' && template_id !== undefined) ? ' d-flex' : ''}${cloneTemplate ? ' active' : ''}`}>
                    <i className="fa fa-clone" onClick={() => clone()}></i>
                  </div>
                </div>
                <div className="single-choise mb-4">
                  <span className="title bg-light">
                    ตัวเลือกแบบตัวเลือกเดียว
                  </span>
                  {BindOption('single')}
                </div>
                <div className="multi-choise">
                  <span className="title bg-light">
                    ตัวเลือกแบบหลายตัวเลือก
                  </span>
                  {BindOption('multi')}
                </div>
              </div>
            </div>
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "104032", defaultMessage: "Options" })}:{" "}
            </p>
            <div
              style={{
                width: "530px",
              }}
            >
              <div className="d-flex align-items-center">
                <Switch
                  checked={changeOption}
                  onChange={() => handleChangeOption()}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  className="input-cheked-show"
                />
                <span className="ml-3">
                  {intl.formatMessage({
                    id: "104033",
                    defaultMessage: "Options for the food menu.",
                  })}
                </span>*
              </div>
              <div
                className={`box-cheked-show${changeOption ? " d-block" : ""}`}
              >
                <div
                  className="template mb-3 d-flex align-items-center"
                  style={{
                    width: "530px",
                  }}
                >
                  <span className="mr-3">
                    {intl.formatMessage({
                      id: "104034",
                      defaultMessage: "pattern",
                    })}
                  </span>
                  <select
                    as="select"
                    style={{
                      flexGrow: "1",
                      height: "38px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    value={template_id}
                    onChange={handleChangeTemplate}
                  >
                    <option value="">
                      {intl.formatMessage({
                        id: "104016",
                        defaultMessage: "please select",
                      })}
                    </option>
                    {template &&
                      template.map((result, index) => {
                        return (
                          <option key={index} value={result.id}>
                            {result.template_name}
                          </option>
                        );
                      })}
                  </select>
                  <div
                    alt="clone template"
                    className={`button-clone d-none${
                      template_id !== "" && template_id !== undefined
                        ? " d-flex"
                        : ""
                    }${cloneTemplate ? " active" : ""}`}
                  >
                    <i className="fa fa-clone" onClick={() => clone()}></i>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <Switch
                    checked={changeName}
                    onChange={() => handleChangeNameOption()}
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="input-cheked-show"
                  />
                  <span className="ml-3">กำหนดชื่อรูปแบบ</span>
                </div>
                <div
                  className={`template name mb-3 align-items-center${
                    changeName ? " d-flex" : ""
                  }`}
                  style={{
                    width: "530px",
                  }}
                >
                  <span className="mr-3">ชื่อรูปแบบ</span>
                  <input
                    name="template name"
                    type="text"
                    min="0"
                    style={{
                      height: "38px",
                      flexGrow: "1",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(templateName) =>
                      setTemplateName(templateName.target.value)
                    }
                  />
                </div>

                <div className="single-choise mb-4">
                  <span className="title bg-light">
                    {intl.formatMessage({
                      id: "104036",
                      defaultMessage: "Single option",
                    })}
                  </span>
                  {BindOption("single")}
                </div>
                {/*<div className="multi-choise">
                  <span className="title bg-light">
                    {intl.formatMessage({
                      id: "104043",
                      defaultMessage: "Multiple Option",
                    })}
                  </span>
                  {BindOption("multi")}
                </div>*/}
              </div>
            </div>
          </div>
          
          {/* <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113263", defaultMessage: "Time couting" })}:{" "}
            </p>
            <Switch
              checked={enableTimeCounting}
              onChange={(e) => setEnableTimeCounting(e.target.checked)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div> */}

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              {intl.formatMessage({ id: "113264", defaultMessage: "Time period (Minute)" })}:{" "}
            </p>
            <input
              name="time_period"
              type="number"
              min="0"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              onChange={(time_period) => setTimePeriod(time_period.target.value)}
              value={timePeriod}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "112042" ,defaultMessage:"Enable" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "113461", defaultMessage: "Buffet Package" })}:{" "}
            </p>
            <Switch
              checked={buffet_package}
              onChange={() => handleChangeSwitchPackage()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/MenuBuffet">
              <button type="button" className="btn btn-secondary wbt mr-5" onClick={() => localStorage.removeItem('edit')}>
                {intl.formatMessage({ id: "112043" ,defaultMessage:"Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "112044" ,defaultMessage:"Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
