import React from 'react';
import A5A6Order from './A5A6Order';
class A6Template extends React.Component {
  render() {
    const { table_name, qr_img, qrCode, logo, cover } = this.props;

    return (
      <React.Fragment>
        <div
          id={`download-${qrCode}`}
          className="qrdiv qr-background"
          style={{
            position: 'relative',
            height: '148.5mm',
            width: '105.8mm',
            display: 'block',
            backgroundColor: '#221f1f', // เพิ่มสีพื้นหลัง
            overflow: 'hidden', // ป้องกันเนื้อหาเกินพื้นที่
          }}
        >
          {/* Background Image */}
          <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 1,
            objectFit: 'cover',
          }}>
            <A5A6Order />
          </div>


          {/* Logo Image */}
          {logo && (
            <img
              src={logo}
              width={80}
              height={80}
              style={{
                position: 'absolute',
                top: '10px',
                left: '8px',
                zIndex: 2,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              alt="Logo"
            />
          )}

          {/* Cover Image */}
          {cover && (
            <img
              src={cover}
              style={{
                position: 'absolute',
                width: '100%', // หรือกำหนด width เท่ากับช่อง
                height: '100%', // หรือกำหนด height เท่ากับช่อง
                top: '0', // เริ่มที่ด้านบน
                left: '0', // เริ่มที่ด้านซ้าย
                zIndex: 0,
                objectFit: 'cover', // ครอบเต็มพื้นที่
                transform: 'translate(0, 0)', // ไม่ให้เลื่อน
              }}
              alt="Cover"
            />
          )}

          {/* QR Code */}
          {qr_img && (
            <div
              style={{
                position: 'absolute',
                background: '#fff',
                padding: '20px',
                borderRadius: '10px',
                top: '35%',
                left: '117px',
                zIndex: 3,
              }}
            >
              <img src={qr_img} alt="QR Code" width={120} height={120} />
            </div>
          )}

          {/* Table Name */}
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '25px',
              fontSize: '50px',
              fontWeight: '900',
              color: '#fff',
              zIndex: 3,
            }}
          >
            {table_name.substring(0, 9)}
          </h1>
        </div>
      </React.Fragment>
    );
  }
}

export default A6Template;
