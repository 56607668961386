import React, { useState, useEffect, useRef } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
    NoRecordsFoundMessage,
    PleaseWaitMessage,
    sortCaret,
    headerSortingClasses,
} from "../../_metronic/_helpers";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useSubheader } from "../../_metronic/layout";
import axios from "axios";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { baseURL, baseURLDASHBOARD, imageURL } from "../service/API.js";
import Modal from "@material-ui/core/Modal";
import "react-pagination-library/build/css/index.css";
import { useSelector } from "react-redux";
import CropperImage from "./component/CropperImage";
import { useIntl } from "react-intl";
import QRCode from "qrcode.react";
import { size } from "lodash";




export const CoverQr = () => {
    const intl = useIntl();
    const { user } = useSelector((state) => state.auth);
    const suhbeader = useSubheader();
    const allDataTable = [];
    const allDataZone = [];
    const allDataDynamicQR = [];
    const [tableQr, setTableQr] = useState(0);
    const [respTableQr, setRespTableQr] = useState([]);
    const [zone, setZone] = useState([]);
    const [zoneCreate, setZoneCreate] = useState();
    const [qrSize, setQRSize] = useState([]);
    const [qrSizeCreate, setqrSizeCreate] = useState();
    const [cover_qr, setCover_QR] = useState();
    const [stateItem_image_path, setStateItem_image_path] = useState({
        previewItem_image_path: null,
        rawItem_image_path: null,
        imageURLItem_image_path: null,
    });
    const [template_url, setTemplateUrl] = useState({
        id: null,
        url: null,
        isCoverUsed: true
    });
    const [reload, setReload] = useState(false);
    const [logoPath, setLogoPath] = useState();
    const [nameImageItem_image_path, setNameImageItem_image_path] = useState("");


    useEffect(() => {
        //console.log("*******qrsizecreate*******",qrSizeCreate)
        localStorage.removeItem('edit')

        getQRSize();
        getSetting();

    }, [reload]);

    useEffect(() => {
        console.log("***********(useeffect)************")
        console.log(cover_qr)
        console.log(qrSizeCreate)

        setSizeQrShow(cover_qr, qrSizeCreate)
        setSizeCoverShow(cover_qr, qrSizeCreate)

    }, [qrSizeCreate, cover_qr]);
    /* qrselectchange */
    const handleSelectChange = (type, size, e) => {
        console.log("********(value)*********", e.target.value)
        console.log("********(type,size)*********")
        console.log("********(type)*********", type)
        console.log("********(size)*********", size)
        setqrSizeCreate(e.target.value);

        setSizeQrShow(type, size)
        setSizeCoverShow(type, size)

    };
    const handleCancelUploadItem_image_path = () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
    };
    //set Text On QR
    const setTextOnQR = (type) => {
        if (type && (type === "1" || type === "2" || type === 1 || type === 2)) {
            // console.log("-------logo1&logo2--------")
            var style = { position: "absolute", top: "8px", left: "180px", zIndex: 1, color: "#ffffff", fontSize: "30px" }

            return { style: style }
        } else if (type === "3" || type === 3) {
            // console.log("-------logo3--------")
            var style = { position: "absolute", top: "20px", left: "260px", zIndex: 1, color: "#ffffff", fontSize: "30px" }

            return { style: style }
        } else if (type === "5" || type === 5) {
            // console.log("-------logo4--------")
            var style = { position: "absolute", top: "8px", left: "60px", zIndex: 1, color: "#ffffff", fontSize: "30px" }

            return { style: style }
        } else if (type === "6" || type === 6) {
            // console.log("-------logo6--------")
            var style = { position: "absolute", top: "8px", left: "150px", zIndex: 1, color: "#ffffff", fontSize: "30px" }

            return { style: style }
        } else if (type === "4" || type === 4) {
            var style = { position: "absolute", top: "100px", left: "50px", zIndex: 1, color: "#ffffff", fontSize: "30px" }

            return { style: style }
        }
        return {
            width: "596px",
            height: "840px"

        };

    }
    //set size function
    const setSizeQrShow = (type, size) => {
        // console.log("*******(Img size)*******", size)
        // console.log("*******( img type)*******", type)
        if (type !== undefined && size !== undefined || type && size) {

            if (type && (type && size === "1" || type && size === "2" || type && size === 1 || type && size === 2)) {
                return { width: "298px", height: "420px", left: "-12px", display: 'block', position: 'relative' }
            } else if (type && size === "3" || type && size === 3) {
                return { width: "420px", height: "298px", left: "-12px", display: 'block', position: 'relative' }
            } else if (type && size === "4" || type && size === 4) {
                return { width: "340px", height: "213px", display: 'block', position: 'relative' }
            } else if (type && size === "5" || type && size === 5) {
                return { width: "234px", height: "341px", display: 'block', position: 'relative' }
            } else if (type && size === "6" || type && size === 6) {
                return { width: "283px", height: "283px", display: 'block', position: 'relative' }
            } else if (type && size === "7" || type && size === 7) {
                return { width: "340px", height: "213px", display: 'block', position: 'relative' }
            }
            return {

                width: "",
                height: ""

            };
        }

    }
    const setLogoShopCover = (type, size) => {
        if (type && (type && size === "1" || type && size === "2" || type && size === 1 || type && size === 2)) {
            var style = { position: "absolute", top: "8px", left: "6px", width: "60px", height: "60px", zIndex: 1, borderRadius: "50%" }
            var imageUrl = logoPath
            return { src: imageUrl, style: style }
        } else if (type && size === "3" || type && size === 3) {
            var style = { position: "absolute", top: "90px", left: "285px", width: "90px", height: "90px", zIndex: 1, borderRadius: "50%" }
            var imageUrl = logoPath
            return { src: imageUrl, style: style }
        } else if (type && size === "4" || type && size === 4 || type && size === "5" || type && size === 5) {
            var style = { position: "absolute", top: "30px", left: "0", width: "65px", height: "65px", zIndex: 1, borderRadius: "50%" }
            var imageUrl = logoPath
            return { src: imageUrl, style: style }
        } else if (type && size === "6" || type && size === 6) {
            var style = { position: "absolute", top: "0", left: "0", width: "283px", height: "283px", zIndex: 1, }
            var imageUrl = ""
            return { src: imageUrl, style: style }
        } else if (type && size === "7" || type && size === 7) {
            var style = { position: "absolute", top: "0", left: "0", width: "340px", height: "213px", zIndex: 1, }
            var imageUrl = ""
            return { src: imageUrl, style: style }
        }
        return {
            width: "596px",
            height: "840px"

        };

    };
    /*setsizecoverqr */
    const setSizeCoverShow = (type, size) => {
        // console.log("-------cover type&size--------")
        // console.log("type==========>",type)
        // console.log("size==========>",size)
        if (type && (type && size === "1" || type && size === "2" || type && size === 1 || type && size === 2)) {
            var style = { position: "absolute", top: "0", left: "0", width: "298px", height: "420px", zIndex: 1, }
            var imageUrl = '../../media/qr_template/A6A5-cover-view.png'
            return { src: imageUrl, style: style }
        } else if (type && size === "3" || type && size === 3) {
            var style = { position: "absolute", top: "0", left: "0", width: "420px", height: "298px", zIndex: 1, }
            var imageUrl = '../../media/qr_template/150X105mm-cover-view.png'
            return { src: imageUrl, style: style }
        } else if (type && size === "5" || type && size === 5) {
            var style = { position: "absolute", top: "0", left: "0", width: "234px", height: "341px", zIndex: 1, }
            var imageUrl = '../../media/qr_template/55X80mm-cover-view.png'
            return { src: imageUrl, style: style }
        } else if (type && size === "6" || type && size === 6) {
            var style = { position: "absolute", top: "0", left: "0", width: "283px", height: "283px", zIndex: 1, }
            var imageUrl = '../../media/qr_template/10x10cm-cover-view.png'
            return { src: imageUrl, style: style }
        } else if (type && size === "4" || type && size === 4) {
            var style = { position: "absolute", top: "0", left: "0", width: "420px", height: "298px", zIndex: 1, }
            var imageUrl = '../../media/qr_template/120x75mm-cover-view.png'
            return { src: imageUrl, style: style }
        }
        return {
            width: "298px", height: "420px"

        };

    }
    const handleQRSetting = async () => {
        let token = localStorage.getItem("Token");
        let apiUrls = `${baseURL}/management/settingQR`
        let data = {
            qr_size_id: qrSizeCreate,
            cover_url: cover_qr,
            shop_id: localStorage.getItem("shop_id")
        }
        let config = {
            method: 'post',
            url: apiUrls,
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: data
        }
        await axios.request(config)
            .then((res) => {
                Swal.fire(
                    intl.formatMessage({ id: "113246", defaultMessage: "Success" }),
                    intl.formatMessage({ id: "113247", defaultMessage: "Success QR Setting" }),
                    "success"
                ).then(() => {


                    if (res.data.length > 0) {
                        setqrSizeCreate(res.data.qr_size_id);
                        setSizeQrShow(res.data.cover_url, res.data.qr_size_id)
                        setSizeCoverShow(res.data.cover_url, res.data.qr_size_id)
                        setReload(!reload);
                    }
                    window.location.reload();

                })

            })
            .catch((err) => {
                Swal.fire(
                    "Error !",
                    "Create Error.",
                    "error"
                );
            });
    };
    const handleChangeItem_image_path = async (e) => {
        if (e.target.files.length) {
            e.preventDefault();
            let file = e.target.files[0];
            e.target.value = '';
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function (e) {
                var image = new Image();
                image.src = e.target.result;
                image.onload = function () {
                    var height = this.height;
                    var width = this.width;
                    console.log("img width====>", width);
                    console.log("img height====>", height);

                    if (/* height < 512 || width < 512 */false) {
                        Swal.fire(
                            "Upload !",
                            "At least you can upload a 512*512 photo size. !! ",
                            "error"
                        );
                        e.target.value = '';
                        return false;
                    } else {
                        setStateItem_image_path({
                            rawItem_image_path: file,
                            imageURLItem_image_path: e.target.result,
                        });
                        e.target.value = '';
                        return true;
                    }
                };
            };
        }
    };
    const handleImageUploadItem_image_path = async (file) => {
        var formdata = new FormData();
        formdata.append("image", file, URL.createObjectURL(file));
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };
        fetch(`${baseURL}/upload/cover/`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                let res = JSON.parse(result);
                setStateItem_image_path({
                    previewItem_image_path: null,
                    rawItem_image_path: null,
                    imageURLItem_image_path: null,
                });
                setCover_QR(`${imageURL}/cover/cover-${res.filename}.${res.mimetype.split('/')[1]}`);
                setNameImageItem_image_path(res.filename + '.' + res.mimetype.split('/')[1]);
            })
            .catch((error) => console.log("error", error));
    };

    const removeImageItem_image_path = async () => {
        setStateItem_image_path({
            previewItem_image_path: null,
            rawItem_image_path: null,
            imageURLItem_image_path: null,
        });
        setCover_QR("");
    };



    ////////  COVER QR
    const getQRSize = async () => {
        console.log("******(get QR size)*******")
        let apiUrls = `${baseURL}/management/qrsize`;
        let token = localStorage.getItem("Token");
        await axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
            .then(async (res) => {
                console.log("QR SIZE-------->", res.data.data);
                setQRSize(res.data.data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    ////////  COVER QR
    const getSetting = async () => {
        console.log("******(get setting)*******")

        let apiUrls = `${baseURL}/management/settingQR`;
        let token = localStorage.getItem("Token");
        await axios({
            method: "get",
            url: apiUrls,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
            .then(async (res) => {
                console.log("responsedata settingQR====>", res.data.data)
                if (res.data.data) {
                    setqrSizeCreate(res.data.data.qr_size_id)

                    setCover_QR(res.data.data.cover_url)
                    setLogoPath(res.data.logo)

                    if (res.data.template_id > 3)
                        setTemplateUrl({
                            id: res.data.template_id,
                            url: res.data.template_url,
                            isCoverUsed: false
                        })
                    else
                        setTemplateUrl({
                            id: res.data.template_id,
                            url: res.data.template_url,
                            isCoverUsed: true
                        })
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Card>
            <CardHeader title={intl.formatMessage({ id: "113256", defaultMessage: "Cover QR" })}></CardHeader>
            <CardBody>
                <div className="container">

                    <div className="row ">
                        <div className="col-md-6">


                            <div className="qr-size-A5">


                                {qrSizeCreate && cover_qr && (qrSizeCreate == 1 || qrSizeCreate == 2 || qrSizeCreate == 3 ? (
                                    <img
                                        style={setSizeQrShow(cover_qr, qrSizeCreate)}
                                        src={cover_qr}
                                    />
                                ) : (""))}
                                {(qrSizeCreate && cover_qr ? (
                                    <img {...setSizeCoverShow(cover_qr, qrSizeCreate)}

                                        alt="Overlay Image"
                                    />
                                ) : (""))}

                                {qrSizeCreate && cover_qr && (qrSizeCreate == 1 || qrSizeCreate == 2 || qrSizeCreate == 3 ? (
                                    <img {...setLogoShopCover(cover_qr, qrSizeCreate)}

                                        alt="Overlay Image"
                                    />
                                ) : (""))}

                                {(qrSizeCreate ? (
                                    <span {...setTextOnQR(qrSizeCreate)}

                                        alt="Overlay Image"
                                    >โต๊ะที่ XX</span>
                                ) : (""))}



                            </div>
                        </div>

                        {/* แถบรายละเอียดรูป */}

                        <div className="col-md-6">
                            <div
                                className="form-inline"
                                style={{
                                    marginBottom: "25px",
                                }}
                            >

                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                >
                                    {intl.formatMessage({ id: "113257", defaultMessage: "QR Size" })} :{" "}
                                </p>

                                <div
                                    className="d-flex mr-2"
                                    style={{
                                        width: "530px",
                                    }}>
                                    <select
                                        as="select"
                                        style={{
                                            flexGrow: "1",
                                            height: "38px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        value={qrSizeCreate}
                                        onChange={(e) => handleSelectChange(cover_qr, qrSizeCreate, e)}
                                    // onChange={(e) => setqrSizeCreate(e.target.value)}
                                    >
                                        <option value="">
                                            {intl.formatMessage({
                                                id: "104035",
                                                defaultMessage: "please select",
                                            })}
                                        </option>
                                        {qrSize &&
                                            qrSize.map((result, index) => {
                                                return (
                                                    <option key={index} value={result.id}>
                                                        {result.size}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                </div>

                            </div>
                            <div className="form-inline" style={{ marginBottom: "25px" }}>
                                <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                                    Cover Image : <br></br>
                                </p>
                                <input
                                    name="cover_qr"
                                    style={{
                                        height: "38px",
                                        width: "530px",
                                        backgroundColor: "#F4F6F9",
                                        borderRadius: "5px",
                                        borderWidth: "0",
                                    }}
                                    disabled
                                    value={cover_qr}
                                />

                                <span className="btn btn-primary btn-file wbt">
                                    เลือก / แก้ไข
                                    <input type="file" onChange={handleChangeItem_image_path} />
                                </span>

                                <button
                                    type="button"
                                    className="btn btn-danger wbt"
                                    onClick={removeImageItem_image_path}
                                >
                                    ลบ
                                </button>
                            </div>

                        </div>

                    </div>
                    {qrSizeCreate == 3 && stateItem_image_path.imageURLItem_image_path ? (
                        <CropperImage
                            image={stateItem_image_path.imageURLItem_image_path}
                            handleCancel={handleCancelUploadItem_image_path}
                            handleUpload={handleImageUploadItem_image_path}
                            scale={1}


                        />
                    ) : ((qrSizeCreate == 2 || qrSizeCreate == 1) && stateItem_image_path.imageURLItem_image_path) ? (

                        <CropperImage
                            image={stateItem_image_path.imageURLItem_image_path}
                            handleCancel={handleCancelUploadItem_image_path}
                            handleUpload={handleImageUploadItem_image_path}
                            scale={1}

                        />) : ("")}
                </div>


            </CardBody>

            {/* {respTableQr.length !== 0 &&
            respTableQr.map(item => {
                return (
                    <CardBody>
                        <div className="container">
                            <div
                                className="form-inline"
                                style={{
                                    marginBottom: "25px",
                                }}
                            >

                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                >
                                    {intl.formatMessage({ id: "113250", defaultMessage: "Table" })} :{" "}
                                </p>

                                <div
                                    className="d-flex mr-2"
                                    style={{
                                        width: "530px",
                                    }}>
                                    <input
                                        style={{
                                            height: "38px",
                                            width: "530px",
                                            backgroundColor: "#F4F6F9",
                                            borderRadius: "5px",
                                            borderWidth: "0",
                                        }}
                                        type="text"
                                        disabled={true}
                                        value={item.table_name}
                                    />

                                </div>
                            </div>


                            <div
                                className="form-inline"
                                style={{
                                    marginBottom: "25px",
                                }}
                            >

                                <p
                                    style={{
                                        marginTop: "15px",
                                        float: "left",
                                        width: "20%",
                                    }}
                                >
                                    {intl.formatMessage({ id: "113240", defaultMessage: "QR" })} :{" "}
                                </p>

                                <div
                                    className="d-flex mr-2"
                                    style={{
                                        width: "530px",
                                    }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "30%", width: "30%" }}
                                        // id={}
                                        value={item.qr_link_1} />
                                </div>
                            </div>
                        </div>
                    </CardBody>)
            })
        } */}
            <div className="row justify-content-center">
                <button className="btn btn-primary" onClick={() => handleQRSetting()}>
                    Save
                </button>
            </div>
        </Card>

    );
}