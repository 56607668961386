import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { baseURL, imageURL } from "../../../service/API.js";
import Switch from "@material-ui/core/Switch";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";
import { temp_store } from "../../../modules/function/temp_store"


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
  noLabel: {
    marginTop: theme.spacing.unit * 3,
  },
  inputColor: {
    backgroundColor: "#F4F6F9",
    borderRadius: "5px",
    borderWidth: "0",
  },
}));

export const EditSalesChannel = (props) => {
  const intl = useIntl();
  const suhbeader = useSubheader();
  const classes = useStyles();
  suhbeader.setTitle(intl.formatMessage({ id: "113293" ,defaultMessage:"Sales channel"}));
  const history = useHistory();
  const [salesch_name_th, setsalesch_name_th] = useState();
  const [salesch_name_en, setsalesch_name_en] = useState();
  const [salesch_name_cn, setsalesch_name_cn] = useState('-');  
  const [tablezone, setTableZone] = useState();
  const [tablezone_id, setTableZone_id] = useState();
  const [isDelivery, setIsDelivery] = useState();
  const [enable_cn, setEnable_cn] = useState(false);

  const [state, setState] = useState(true);

  console.log("history",history)
  //const [displayOrder, setDisplayOrder] = useState(0)
  // const [zoneprinter_id, setzoneprinter_id] = useState();
  // const [zoneprinterData, setzoneprinterData] = useState([{ id: 1, name: "test1" }, { id: 2, name: "test2" }]);

  const AlertError = (message) => {
    Swal.fire("Menu Group!", message, "error");
  };

  useEffect(() => {
    setsalesch_name_th(temp_store(props).location.state.row.salesch_name_th);
    setsalesch_name_en(temp_store(props).location.state.row.salesch_name_en);
    setsalesch_name_cn(temp_store(props).location.state.row.salesch_name_cn);
    setState(temp_store(props).location.state.row.enabled);
    checkEnableCN();
    getTableZone();

  }, []);

  const handleChangeSwitch = () => {
    setState(!state);
  };
  const handleChangeGroup = (event) => {
    setTableZone_id(event.target.value);
  };
  const handleChangeIsDelivery= (event) => {
    setIsDelivery(event.target.value);
    //console.log("this is channel type>>>>>>>>>",event.target.value);
  }

  const checkEnableCN =()=> {
    let temp = localStorage.getItem('is_enable_cn')
    if (temp == '1') {
      setEnable_cn(true)
    }
  }

  const getTableZone = async () => {
    let apiUrls = `${baseURL}/management/tablezone?limit=10000&page=1`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "get",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        "Accept-Language": "th",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (res) => {
        setTableZone(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const onSubmit = async (data) => {
    if (salesch_name_th === undefined || salesch_name_th === "") {
      return AlertError("please insert name th.");
    }

    if (salesch_name_en === undefined || salesch_name_en === "") {
      return AlertError("please insert name en.");
    }



    var events = {
      salesch_name_th,
      salesch_name_en,
      salesch_name_cn: ( (salesch_name_cn === undefined || salesch_name_cn === "" || salesch_name_cn === null) ? '-' : salesch_name_cn ),
      enabled: state,
    };

    console.log('events', events);

    let apiUrls = `${baseURL}/management/saleschannel/${temp_store(props).location.state.row.id}`;
    let token = localStorage.getItem("Token");
    const resultAll = axios({
      method: "put",
      url: apiUrls,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: events,
    })
      .then(async (res) => {
        history.goBack();
        console.log('history.goBack()')
      })
      .catch((err) => {
        Swal.fire(
          "Edit !",
          "Your can not update sales channel. !! " + err,
          "error"
        );
      });
  };

  // const handleChangezoneprinter = (event, values) => {
  //   if (values) {
  //     setzoneprinter_id(values.id);
  //   } else {
  //     setzoneprinter_id("");
  //   }
  // };

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: "113294", defaultMessage: "Edit Sales Channel " })}></CardHeader>
        <CardBody>
        <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113299", defaultMessage: "Channel Type" })}
            </p>
            <div
              className="d-flex"
              style={{
                width: "530px",
              }}>
              <select
                as="select"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={isDelivery}
                onChange={handleChangeIsDelivery}
              >
                <option value="">{intl.formatMessage({ id: "104016", defaultMessage: "please select" })}</option>
                <option value="1">{intl.formatMessage({ id: "113297", defaultMessage: "Delivery channel" })}</option>
                <option value="0">{intl.formatMessage({ id: "113298", defaultMessage: "Offline channel" })}</option>
 
              </select>

            </div>
            </div>
        <div className={`box-cheked-show${isDelivery==0 ? ' d-block' : ''}`}>        
        <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113298", defaultMessage: "Offline channel" })}
            </p>
            <div
              className="d-flex"
              style={{
                width: "530px",
              }}>
              <select
                as="select"
                style={{
                  flexGrow: "1",
                  height: "38px",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                value={tablezone_id}
                onChange={handleChangeGroup}
              >
                <option value="">{intl.formatMessage({ id: "104016", defaultMessage: "please select" })}</option>
                {tablezone &&
                  tablezone.map((result, index) => {
                    return (
                      <option key={index} value={result.id}>
                        {result.zone_name}
                      </option>
                    );
                  })}
              </select>

            </div>
        </div>
        </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "103013", defaultMessage: "Thai name" })}:{" "}
            </p>
            <input
              name="salesch_name_th"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={salesch_name_th}
              onChange={(e) => setsalesch_name_th(e.target.value)}
            />
          </div>
          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "103014", defaultMessage: "english name" })}:{" "}
            </p>
            <input
              name="salesch_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={salesch_name_en}
              onChange={(e) => setsalesch_name_en(e.target.value)}
            />
          </div>
          {enable_cn && <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
              <span style={{ color: "red" }}>
                *
              </span>
              {intl.formatMessage({ id: "113131", defaultMessage: "chinese name" })}:{" "}
            </p>
            <input
              name="salesch_name_en"
              style={{
                height: "38px",
                width: "530px",
                backgroundColor: "#F4F6F9",
                borderRadius: "5px",
                borderWidth: "0",
              }}
              value={salesch_name_cn}
              onChange={(e) => setsalesch_name_cn(e.target.value)}
            />
          </div>}

       

          {/* <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              Zone printer :{" "}
            </p>

            {zoneprinterData.length > 0 ? (
              <div>
                <Autocomplete
                  id="zone printer"
                  options={zoneprinterData}
                  getOptionLabel={(option) => option.name}
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  onChange={handleChangezoneprinter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.inputColor}
                      style={{
                        borderWidth: 0,
                      }}
                      label="Zone printer"
                    />
                  )}
                />
              </div>
            ) : null}

          </div> */}


          <div className="form-inline" style={{ marginBottom: "25px" }}>
            <p
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            >
              {intl.formatMessage({ id: "103016", defaultMessage: "enable" })}:{" "}
            </p>
            <Switch
              checked={state}
              onChange={() => handleChangeSwitch()}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </div>

          <div
            className="form-inline"
            style={{
              marginBottom: "25px",
            }}
          >
            <div
              style={{
                marginTop: "15px",
                float: "left",
                width: "20%",
              }}
            ></div>
            <Link to="/MenuGroup">
              <button type="button" className="btn btn-secondary wbt mr-5" onClick={()=>localStorage.removeItem('edit')}>
                {intl.formatMessage({ id: "#103017", defaultMessage: "Cancel" })}
              </button>
            </Link>
            <button className="btn btn-primary" onClick={() => onSubmit()}>
              {intl.formatMessage({ id: "#103018", defaultMessage: "Save" })}
            </button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
