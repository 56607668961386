import React from 'react';
import A5A6Order from './A5A6Order';

class A5Template extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      table_name: this.props.table_name,
      qr_img: this.props.qr_img,
      cover: this.props.cover,
      logo: this.props.logo,
    };
  }

  render() {
    return (
      <>
        <div
          id="testqrdiv"
          className="qrdiv"
          style={{
            height: '213.8mm',
            width: '148.8mm',
            position: 'relative',
            display: 'block',
            backgroundColor: '#221f1f', // เพิ่มสีพื้นหลัง
            overflow: 'hidden', // ป้องกันเนื้อหาเกินพื้นที่
          }}
        >

          <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
          }}>
            <A5A6Order />
          </div>


          {/* โลโก้ร้าน */}
          <img
            src={this.state.logo}
            width={111}
            height={111}
            style={{
              position: 'absolute',
              top: '14px',
              left: '12px',
              zIndex: 1,
              borderRadius: '50%',
              objectFit: 'cover',
            }}
            alt="logo"
          />

          {/* รูปภาพหน้าปก */}

          <img
            src={this.state.cover}
            style={{
              width: '100%', // ใช้ความกว้างเต็มพื้นที่
              height: 'auto', // รักษาสัดส่วนภาพ
              objectFit: 'cover', // ครอบเต็มพื้นที่ในกรอบ
              objectPosition: 'center', // จัดตำแหน่งตรงกลางของภาพ (X และ Y)
            }}
            alt="cover"
          />
          {/* </div> */}



          {/* QR Code */}
          <div
            style={{
              position: 'absolute',
              background: '#fff',
              padding: '15px',
              borderRadius: '5px',
              left: '168px',
              top: '280px',
              zIndex: 2,
            }}
          >
            <img src={this.state.qr_img} alt="QR Code" width={200} height={200} />
          </div>

          {/* ชื่อโต๊ะ */}
          <h1
            style={{
              position: 'absolute',
              right: '20px',
              top: '35px',
              fontSize: '60px',
              fontWeight: '900',
              color: '#fff',
              zIndex: 2,
            }}
          >
            {this.state.table_name}
          </h1>
        </div>
      </>
    );
  }
}

export default A5Template;
