import React from 'react';
import ThermalV2Order from './ThermalV2Order';
const ThermalTemplate = ({ table_name, qr_img, qrCode }) => {
  return (
    <div
      id={`download-${qrCode}`}
      className="qrdiv qr-background"
      style={{
        position: 'relative',
        height: '75mm',
        width: '121.8mm',
        display: 'block',
      }}
    >
      {/* Inject SVG content */}
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
      >
        <ThermalV2Order />
      </div>

      {/* QR Code */}
      <div
        style={{
          position: 'absolute',
          top: '32px',
          right: '29px',
          zIndex: 2,
        }}
      >
        <img src={qr_img} alt="QR Code" width={180} height={180} />
      </div>

      {/* Table Name */}
      <h1
        style={{
          position: 'absolute',
          left: '25px', // เริ่มจากขอบซ้าย
          right: '250px', // กันพื้นที่ด้านขวา เพื่อไม่ให้ข้อความชน QR Code
          top: '40%', // จัดให้อยู่กึ่งกลางในแนวตั้ง
          transform: 'translateY(-50%)', // จัดให้กึ่งกลางในแนวตั้งอย่างสมบูรณ์
          fontSize: '50px',
          fontWeight: '900',
          color: '#fff',
          zIndex: 2,
          textAlign: 'center', // จัดให้อยู่ตรงกลางในแนวนอน
          whiteSpace: 'nowrap', // ป้องกันข้อความถูกตัดบรรทัด
        }}
      >
        {table_name}
      </h1>

    </div>
  );
};

export default ThermalTemplate;

